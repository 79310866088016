/**
 * Internet Explorer 8 Specific Fixes
 */

.homepage-hero {
   -ms-behavior: url(/backgroundsize.min.htc);
}
.listed-by-card {
   .listed-img-office {
      -ms-behavior: url(/backgroundsize.min.htc);
   }
   .listed-img-person {
      -ms-behavior: url(/backgroundsize.min.htc);
   }
}
.results-card {
   .card-photo {
      -ms-behavior: url(/backgroundsize.min.htc);
   }
}
.page-title {
   -ms-behavior: url(/backgroundsize.min.htc);
}
.panel-graphicpanel {
   -ms-behavior: url(/backgroundsize.min.htc);
}
#search-results-quickview {
   /* Force hidden quickview by setting outline width to 8px, see CbcSearchResults.js */
   outline-width: 8px;
}

body > header .nav-search .quick-search-container .dropdown .dropdown-menu {
   max-height: 431px;
}

body > header .nav-search .quick-search-container .tab-item.tab-properties {
   background-image: url("../img/quick-search/quick-search-properties.png");
}

body > header .nav-search .quick-search-container .tab-item.tab-professionals {
   background-image: url("../img/quick-search/quick-search-professionals.png");
}

body > header .nav-search .quick-search-container .tab-item.tab-offices {
   background-image: url("../img/quick-search/quick-search-locations.png");
}


.results-card-professional .card-photo{

   .card-award-flag{
      top: 135px !important;
      width: 120px !important;
   }
}


.property-type-icon-FAR {
   background-image: url("../img/filters/filter-agriculture-13x13.png");
}

.property-type-icon-HOS {
   background-image: url("../img/filters/filter-hospitality-13x13.png");
}

.property-type-icon-IND {
   background-image: url("../img/filters/filter-industrial-13x13.png");
}

.property-type-icon-LAN {
   background-image: url("../img/filters/filter-land-13x13.png");
}

.property-type-icon-MUL {
   background-image: url("../img/filters/filter-multi-family-13x13.png");
}

.property-type-icon-OFF {
   background-image: url("../img/filters/filter-office-13x13.png");
}

.property-type-icon-RET {
   background-image: url("../img/filters/filter-retail-13x13.png");
}

.property-type-icon-SPE {
   background-image: url("../img/filters/filter-special-purpose-13x13.png");
}